import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BasicVideo = makeShortcode("BasicVideo");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "a-natural-parental-instinct"
    }}>{`A natural parental instinct?`}</h1>
    <p>{`It can be really uncomfortable to watch your teenager getting distressed due
to their anxiety. When this happens, your gut reaction may be to swoop in with
the best of intentions, to remove the cause of the distress for both you and
your teenager.`}</p>
    <p>{`Watch how this unfolds for Liliana and her mum Jenny.`}</p>
    <BasicVideo url="https://player.vimeo.com/video/575137896" mdxType="BasicVideo" />
    <p>{`Jenny’s behaviours are known as `}<strong parentName="p">{`‘parental accommodation’`}</strong><sup>{`2`}</sup>{` – changes parents make in their behaviours and routines in response
to their child’s anxiety, in an attempt to prevent or reduce their child’s distress.`}</p>
    <p>{`Notice how Jenny became tense herself as Liliana described her anxieties, and
went straight into ‘Mum-in-charge’ mode – reassuring Liliana, going to
significant (and costly) efforts to ease Liliana’s concerns, and offering to
‘rescue’ Liliana if necessary – even texting Liliana’s friend on her behalf.`}</p>
    <p>{`Parental accommodation is common amongst parents of children who have a
tendency to get anxious. And it’s often the natural instinct of loving
parents!`}</p>
    <p>{`Accommodating behaviours are usually successful in reducing the teenager’s
(and parent’s) distress – in the moment. This makes it more likely for the
teenager to become dependent on the parent to help them cope with anxiety in
the future. So parental accommodation can make anxiety problems worse in the
long run because it prevents teenagers from developing helpful strategies to
manage their anxiety on their own.`}</p>
    <sup>
  2. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      